<template>
    <div class="p-grid">
        <div class="p-col-12 p-d-flex p-jc-center p-mt-5">
            <img class="img-fluid" src="../../assets/puzzle.jpg" alt="">
        </div>
        <div class="p-col-10 p-offset-1">
            <p class="">El <strong>Centro Médico Beatriz Ayllón</strong> está orientado a la ayuda integral del paciente
                tanto en la autorregulación de las emociones y la ayuda contra el dolor, como en el aspecto más estético
                facial y corporal, que te ayudará a sentirte mejor contigo mismo.
                Somos un equipo de profesionales cuyo único objetivo es ayudarte a mejorar tu salud desde dos enfoques
                completamente diferentes pero no opuestos.</p>

            <p class="">Creemos en un centro donde es posible ofrecer beneficios para la salud <strong>física y
                    mental</strong> combinando
                las mejores <strong>terapias psicológicas,físicas y estéticas</strong>,sin dejar de lado la profesionalidad.
            </p>

            <p class="">
                Nosotros nos encargaremos de asesorarte e informarte acerca de cómo puedes beneficiarte de <strong>bonos y
                    tarifas</strong> para reforzar tu <strong>salud física y tranquilidad
                    mental</strong>, tanto para ti como para tus seres
                queridos.
            </p>
        </div>
    </div>
</template>

<script>
export default {
    name: 'QuienesSomosVue',
    data() {
        return {
            // Propiedades de datos del componente
        };
    },
    methods: {
        // Métodos del componente
    },
    computed: {
        // Propiedades computadas del componente
    },
    created() {
        // Lógica que se ejecuta cuando el componente se crea
    },
    mounted() {
        // Lógica que se ejecuta cuando el componente se monta en el DOM
    },
    destroyed() {
        // Lógica que se ejecuta cuando el componente se destruye
    },
};
</script>

<style scoped>
/* Estilos específicos del componente */
.img-fluid {
  width: 100%;
  height: auto;
}
</style>

